import request from '../utils/request'

export default {
    spgl_cx(data) {
        return request({
            url: 'api/fpgl/spgl_cx_page',
            method:'post',
            data
        })
    },
    spgl_sc(data) {
        return request({
            url: 'api/fpgl/spgl_delete',
            method:'post',
            data
        })
    },
    spgl_bc(data) {
        return request({
            url: 'api/fpgl/spgl_insert',
            method:'post',
            data
        })
    },
    spgl_ssfl(data) {
        return request({
            url: 'api/fpgl/spgl_cx_ssfl',
            method:'post',
            data
        })
    },
    spgl_xg(data) {
        return request({
            url: 'api/fpgl/spgl_update',
            method:'post',
            data
        })
    },
    
}