<template>
    <div class="main">
        <div style="margin-top:10px;">
            <van-search
                v-model="objData.spmc"
                show-action
                shape="round"
                placeholder="请输入商品名称"
                @search="onSearch"
                @cancel="onCancel"
            >
            </van-search>
        </div>
        <div v-if="showlist">    
            <van-cell center >
                <div class="qjListItem">
                    <div class="divTitle divbold">税收分类编码</div>
                    <div class="divTitle divbold">商品名称</div>
                </div>
            </van-cell>
            <van-cell center v-for="(item,index) in objData.cxArr" :key="index"  @click="Rowclick(item)">
                <div class="qjListItem">
                    <div class="divTitle">{{item.bm}}</div>
                    <div class="divTitle">{{item.hwhlwmc}}</div>
                </div>
            </van-cell>
        </div>
    </div>
</template>

<script>
import { ref,reactive,getCurrentInstance } from 'vue';
import {  Notify} from 'vant';
import Request from '../../api/commodity'
export default {
    name: "comdSpfl",
    
    setup(){
        const { proxy } = getCurrentInstance();
        const showlist = ref(false);
        const objData = reactive({
            spmc: "",
            page: 1,
            pageSize: 10,
            cxArr:[]
        });
        const onSearch = ()=> {
            var params={
                gjz:objData.spmc,
            }
            console.log(params);
            Request.spgl_ssfl(params)
            .then(res=>{
                console.log('res=>',res); 
                if (res.returnCode === "00") {     
                    objData.cxArr = res.rows;
                    showlist.value = true;  
                }else{
                    showlist.value = false;
                    Notify({ type: 'danger', message: res.returnMessage });
                }
            }).catch(err=>{
                console.log('err=>',err);   
            })
        };
        const onCancel = () =>{
            // Toast('取消');
            proxy.$root.$router.go(-1);
        } 
        const Rowclick = (val) => {
            console.log(val);
            proxy.$root.$router.push({path:"/compAdd", query:val});
            proxy.$root.$router.go(-1);
        };
        
        // onMounted(()=>{
        //     onSearch();
        // })
        return{
            onSearch,
            objData,
            onCancel,
            Rowclick,
            showlist,
        };
        
    }
}
</script>

<style scoped>
    .main {
        min-height: 100vh;
        position: relative;
    }
    .qjListItem{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .divTitle{
        flex: 1;
        text-align: center;
    }
    .divbold{
        font-weight: bold;
    }

</style>